<template>
  <div>
    <header class="mb-3">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <sg-select :items="blocks" v-model="selectedBlock" label="block_name" :prepend="$t('farm.block')" :placeholder="`${$t('select')} ${$t('farm.block')}`"></sg-select>

        <div v-if="canAddPond">
          <b-button type="is-danger is-gradient" icon-left="plus" class="mr" @click="addPond">
            {{ $t('add') }} {{ $t('farm.pond') }}
          </b-button>
        </div>
      </div>
    </header>
    <!--   User List  -->

    <section>
      <b-table :mobile-cards="false" :data="ponds" bordered>
        <template #empty>
          <p class="has-text-centered">{{ $t('no') }} Data</p>
        </template>

        <b-table-column field="name" :label="$t('farm.pond_name')" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="action" label="" centered width="120" v-slot="props" v-if="canEditPond">
          <div class="is-flex">
            <b-button type="is-secondary is-light" @click="editPond(props.row)" icon-left="pencil-outline"></b-button>
            <b-button class="ml-2" type="is-danger is-light" @click="deletePond(props.row.pond_id)" icon-left="trash-can-outline"></b-button>
          </div>
        </b-table-column>
      </b-table>
    </section>

    <!--  Modal Add Pond -->

    <b-modal
        v-model="addModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <form-pond :selected-block="selectedBlock" :default-value="selectedPond" :blocks="blocks" is-modal @submit="getUser"
                   @close="props.close"></form-pond>
      </template>
    </b-modal>

  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapGetters} from "vuex";

export default {
  name: "Pond",
  components: {
    FormPond: () => import("@/components/Farm/Pond/FormPond"),
    SgSelect
  },
  data: () => {
    return {
      selectedBlock: null,
      current: 1,
      addModal: false,
      addRoleModal: false,
      detailModal: false,
      data: [],

      selectedPond: null,
      search: '',
    }
  },
  watch: {
    'selectedFarm.farm_id'() {
      this.getUser()
    }
  },
  computed: {
    ...mapGetters('farm', [
      'canAddPond',
      'canEditPond',
    ]),
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    blocks() {
      return this.selectedFarm.blocks
    },
    ponds() {
      return this.selectedBlock ? this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id) : []
    },
  },
  created() {
    this.selectedBlock = this.blocks[0]
    this.$loading(false)
  },
  methods: {
    addPond() {
      this.selectedPond = null
      this.addModal = true
    },

    editPond(pond) {
      this.selectedPond = pond
      this.addModal = true
    },

    deletePond(pond_id) {
      this.$delete(async () => {
        this.$loading()
        let res = await this.$store.dispatch('farm/deletePond', pond_id)
        if (!res.error) {
          await this.$store.dispatch('farm/getBlock')
          await this.$store.dispatch('farm/getPond')
        }

        this.$loading(false)
      })
    },
  }
}
</script>
